$frame-deco: '../images/decoration/frame-deco.png';

/* Default Card */
.default-card {
  direction: ltr;
  border-radius: 12px;
  width: 240px;
  height: 350px;
  padding: spacing(3, 2);
  margin: spacing(4, 1);
  &.card {
    overflow: visible;
  }
  figure {
    border-radius: 12px;
    box-shadow: 0 1.5px 12px 6px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    height: 125px;
    margin: spacing(-5, 0, 2);
    img {
      height: 100%;
      min-width: 100%
    }
  }
  h6 {
    margin-bottom: $spacing2
  }
  p {
    height: 90px;
    overflow: hidden;
    margin-bottom: $spacing3
  }
  .button {
    box-shadow: none
  }
}

/* Testimonial Card */
.testi-card {
  direction: ltr;
  position: relative;
  .paper.card {
    padding: $spacing3;
    width: 240px;
    height: 240px;
    border-radius: 50px 50px 50px 0;
    p {
      height: 130px;
    }
  }

  .rating {
    margin-top: $spacing4
  }

  .star-icon {
    color: #FFC107
  }

  .star-icon-disable {
    .theme--light & {
      color: $light-divider;
    }
    .theme--dark & {
      color: $dark-divider;
    }
  }

  .person {
    display: flex;
    margin-top: $spacing3;
    .avatar {
      width: 55px;
      height: 55px;
    }
    .name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include margin-left($spacing2);
      h6 {
        font-weight: $font-medium
      }
    }
    .caption {
      font-style: italic;
      font-weight: 300
    }
  }

  .avatar {
    border: 4px solid #FFF;
    box-sizing: content-box;
    @include shadow2;
  }
}




/* Case Studies Card */
.case-card {
  box-shadow: none;
  max-height: 390px;
  height: auto !important;
  border-radius: 24px;
  margin-bottom: $spacing3;
  overflow: hidden;
  position: relative;
  text-transform: none !important;
  padding: 0 !important;
  background: $palette-secondary-light !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @include breakpoints-up(lg) {
    min-height: 148px
  }
  .figure {
    margin: 0;
    display: inline-block;
    img {
      width: 100%;
      left: -1px;
      position: relative;
    }
  }
  .title {
    line-height: 22px;
    margin-bottom: $spacing2;
    font-size: 1.25rem;
    font-weight: 500
  }
  .logo {
    display: block;
    position: relative;
    img {
      width: 100%;
      z-index: 1;
    }
  }
  &.small {
    .title {
      text-align: center;
    }
    .logo {
      margin: -16px auto 16px !important
    }
    .property {
      text-align: center !important;
      bottom: 0 !important;
      .title {
        display: block;
      }
    }
    .figure {
      @include breakpoints-down(xs) {
        width: 100%
      }
    }
  }
  &.big {
    .property {
      bottom: -50px;
      @include breakpoints-down(md) {
        text-align: center;
        bottom: 0
      }
    }
  }
  &:hover {
    .property {
      @include breakpoints-up(lg) {
        background: $palette-primary-main;
        height: 100%;
        bottom: 0px;
        opacity: 1;
        p {
          opacity: 1;
          bottom: 0
        }
      }
    }
  }
  .property {
    transition: all 0.3s ease-out;
    position: absolute;
    width: 100%;
    height: 150%;
    min-height: 170px;
    padding: $spacing3 $spacing2;
    bottom: -72px;
    @include text-align(left);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include left(0);
    color: $palette-common-white;
    z-index: -1;
    background: linear-gradient(to bottom, rgba(238, 238, 238, 0) 20%, #000 90%);
    .desc {
      font-weight: $font-regular;
      font-size: 16px;
      white-space: normal
    }
    @include breakpoints-down(md) {
      padding: $spacing1;
      text-align: center;
      bottom: 0;
      .desc {
        display: none
      }
    }
    .title {
      white-space: normal;
      @include breakpoints-up(lg) {
        display: flex
      }
    }
    .logo {
      width: 64px;
      height: 64px;
      background: url($frame-deco) no-repeat;
      padding: $spacing2;
      text-align: center;
      @include margin-right($spacing2);
      background-size: 100%;
      @include breakpoints-down(md){
        display: none;
      }
      img {
        display: block
      }
    }
    h6 {
      color: $palette-common-white;
      margin-bottom: $spacing2;
      line-height: 22px
    }
    &.full-hide {
      background: none;
      opacity: 0;
    }
    p {
      opacity: 0;
      transition: bottom 0.5s ease-out;
      position: relative;
      bottom: -20px
    }
  }
}

