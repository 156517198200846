#testimonials {
  .root {
    width: 100%;
    .theme--dark & {
      @include bg-color-darken($palette-primary-light, 0.8)
    }
    .theme--light & {
       @include bg-color-lighten($palette-primary-light, 0.8)
    }
    position: relative;
    padding: spacing(10, 0, 3);
    @include breakpoints-down(md) {
      padding-top: $spacing15,
    }
  }

  .carousel {
    position: relative;
    z-index: 3;
    padding-bottom: $spacing5;
    .slick-dots {
      bottom: $spacing8 * -1;
      li {
        width: 10px;
        height: 10px;
        transition: width 0.3s ease;
        background: $palette-secondary-light;
        border-radius: 15px;
        margin: spacing(0, 0.5);
        &.slick-active {
          width: 35px
        }
        button {
          opacity: 0
        }
      }
    }
    @include breakpoints-up(lg) {
      @include margin-left(-80px)
    }
  }

  .item {
    padding: $spacing2;
    &:focus {
      outline: none
    }
  }

  .floating-title {
    position: absolute;
    width: 100%;
    @include left(0);
    top: $spacing5;
    @include breakpoints-up(lg) {
      @include left($spacing3);
      top: $spacing10 * -1
    }
    @media (min-width: 1400px) {
      @include left($spacing10)
    }
    .title {
      @include breakpoints-up(md) {
        @include margin-right($spacing8)
      }
      @include breakpoints-up(lg) {
        @include float(right)
      }
      @media (min-width: 1400px) {
        @include margin-right(-40px)
      }
    }
  }

  .item-props {
    &-first {
      [dir="rtl"] & {
        width: 450px
      }
      [dir="ltr"] & {
        width: 160px
      }
    }
    &-last {
      [dir="rtl"] & {
        width: 160px
      }
      [dir="ltr"] & {
        width: 350px
      }
    }
  }
}
