.counter-wrap {
  margin: spacing(3, 0);
}

.counter-item {
  p {
    text-transform: capitalize
  }
  @include breakpoints-up(md) {
    display: flex;
  }
  align-items: center;
  justify-content: center;
  .text {
    text-align: center;
    h3 {
      position: relative;
      font-weight: bold;
      padding-bottom: $spacing2;
      margin-bottom: $spacing4;
      &:after {
        content: "";
        width: 50px;
        height: 8px;
        border-radius: 5px;
        background: $palette-primary-main;
        position: absolute;
        bottom: $spacing2 * -1;
        left: calc(50% - 25px)
      }
    }
  }
}