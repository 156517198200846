#banner {
  // Media query class
  .container.fixed {
    @media (min-width: 960px) {
      max-width: 960px;
    }
    @media (min-width: 1280px) {
      max-width: 1280px;
    }
  }
  .hero-content {
    position: relative;
    > .container {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin: 0 auto;
    }
    @include breakpoints-up(lg) {
      @include padding-left($spacing6);
      margin-bottom: 0;
    }
    @include breakpoints-down(sm) {
      margin-bottom: 0;
      padding-bottom: $spacing12;
    }
  }

  .banner-text {
    position: relative;
    z-index: 4;
    padding: spacing(6, 0);
    @include breakpoints-up(md) {
      @include palette-text-primary;
    }
    @include breakpoints-down(md) {
      padding: $spacing4;
    }
    @include breakpoints-down(sm) {
      padding: $spacing4;
      max-width: 640px;
      margin: 0 auto;
      text-align: center;
      color: $palette-common-white;
    }
  }

  .title {
    margin-top: $spacing12;
    margin-bottom: $spacing2;
    @include breakpoints-down(md) {
      margin-top: $spacing15;
    }
    h3 {
      font-family: 'Roboto Condensed',
    }
  }

  .text-helper {
    display: inline-block;
    @include breakpoints-up(lg) {
      @include padding-right($spacing15);
    }
  }

  .second-row {
    display: flex;
    align-items: center;
  }

  .subtitle {
    margin-bottom: $spacing3;
    @include breakpoints-up(lg) {
      @include padding-right($spacing8)
    }
    @include breakpoints-up(md) {
      margin-bottom: $spacing5
    }
  }

  .button {
    min-width: 200px;
    @include breakpoints-down(sm) {
      color: $palette-secondary-light !important;
      border-color: $palette-secondary-light;
    }
  }

  .icon {
    @include margin-left($spacing1);
    line-height: 22px;
    top: 6px;
    position: relative;
    [dir="rtl"] & {
      transform: rotate(180deg);
    }
  }

  .mobile-cover {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    height: 100%;
    background: $palette-common-black;
    img {
      min-width: 100%;
      height: 100%;
      opacity: 0.5;
      @include breakpoints-down(xs) {
        opacity: 0.4;
      }
    }
  }

  .video-wrap {
    position: relative;
  }

  .video-figure {
    border-radius: 80px;
    background: $palette-primary-main;
    overflow: hidden;
    position: absolute;
    width: 1040px;
    height: 840px;
    top: 0;
    @include left(0);
    [dir="rtl"] & {
      box-shadow: 30px 20px 0px 0px $palette-primary-main;
    }
    [dir="ltr"] & {
      box-shadow: -30px 20px 0px 0px $palette-primary-main;
    }
    @include breakpoints-up(sm) {
      top: -920px;
      @include left(-110px);
      [dir="rtl"] & {
        transform: rotate(75deg);
      }
      [dir="ltr"] & {
        transform: rotate(-75deg);
      }
    }
    @include breakpoints-up(md) {
      [dir="rtl"] & {
        transform: rotate(45deg);
      }
      [dir="ltr"] & {
        transform: rotate(-45deg);
      }
      width: 1200px;
      top: -700px;
      @include left(-200px);
    }
    @include breakpoints-up(lg) {
      top: -650px;
    }
  }

  #youtube {
    position: relative;
    background: transparent;
    width: 100%;
    height: 100%;
  }

  .ytplayer-container{
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: $palette-common-black;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    z-index: 10
  }

  .inner-figure {
    background: $palette-common-black;
    width: 100%;
    height: 100%;
    position: relative;
    @include breakpoints-up(sm) {
      top: -140px;
      @include left(400px);
      [dir="rtl"] & {
        transform: rotate(-75deg);
        transform-origin: top right;
      }
      [dir="ltr"] & {
        transform: rotate(75deg);
        transform-origin: top left;
      }
    }
    @include breakpoints-up(md) {
      [dir="rtl"] & {
        transform: rotate(-45deg);
      }
      [dir="ltr"] & {
        transform: rotate(45deg);
      }
      @include left(130px);
    }
    img {
      display: block;
      width: 100%;
      z-index: 2;
      position: relative;
    }
    .video {
      display: block;
      width: 100%;
      height: 100%;
      top: 150px;
      position: fixed;
      [dir="ltr"] & {
        left: 30px;
      }
      [dir="rtl"] & {
        right: -50px;
        z-index: -1
      }
      iframe {
        width: 100%
      }
    }
    &:hover {
      .btn-play {
        opacity: 1
      }
    }
  }

  .btn-play {
    position: absolute;
    top: 46%;
    @include left(46%);
    z-index: 200;
    width: 80px;
    height: 80px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s ease;
    background: #FFF !important;
    @include shadow3;
    .material-icons {
      font-size: 50px;
      line-height: 80px;
      color: $palette-primary-main;
      &.play {
        display: none
      }
    }
  }
}