#map-address {
  .root {
    position: relative
  }
  #map {
    height: 100%;
  }
  .block {
    margin-top: $spacing5;
    @include breakpoints-up(md) {
      margin-top: $spacing20
    }
  }

  .paper {
    padding: spacing(2, 5, 1.5);
    margin-bottom: $spacing4;
    @include breakpoints-down(xs) {
      padding: spacing(3, 2)
    }
  }

  .icon {
    color: $palette-primary-main;
    @include margin-right($spacing1);
    margin-top: $spacing2;
    top: 4px;
    position: relative
  }

  .map {
    background: #dedede;
    overflow: hidden;
    width: 100%;
    height: 700px;
    .GMap > div {
      height: 700px
    }
    @include breakpoints-up(md) {
      margin-top: $spacing10
    }
    @include breakpoints-down(sm) {
      margin-top: $spacing6 * -1
    }
  }
}
